html, body {
    height: 100%;
    margin: 0;
  }
  
  .app-layout {
    display: flex;
    flex-direction: column;
    height: 100vh;
  }
  
  header {
    flex: 0 0 auto;
  }
  
  main {
    flex: 1 0 auto;
    padding: 20px 0;
  }
  
  footer {
    flex: 0 0 auto;
  }
  